<!--
@component A video that autoplays only while on–screen, no controls.

This is an important optimization because otherwise, mobile devices can lag or
crash due to having too many videos playing at once.
-->

<script lang="ts">
  import { onMount } from "svelte";

  /** Placeholder image for this video, highly recommended to prevent layout shift. */
  export let poster: string | undefined = undefined;

  let clazz: string = "";
  export { clazz as class };

  /** How the video should fit within its container. */
  export let objectFit: "fill" | "cover" | "contain" = "contain";

  /** Whether the video should be inset within its relative parent (absolute and inset-0). */
  export let inset: boolean = false;

  let element: HTMLVideoElement;

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            element.play();
          } else {
            element.pause();
          }
        });
      },
      // when any amount of the element is on screen
      { threshold: 0.01 },
    );

    observer.observe(element);
    return () => observer.disconnect();
  });
</script>

<!--
Notes:
- `muted` is required for autoplay on most browsers.
- `playsinline` is required for iOS, otherwise it will appear full-screen and
  disrupt the user's browsing.
- `loop` is optional, but often used for background videos.
- Sources can be H.264/AVC or a combination of H.265/HEVC + VP9 (WebM). The
  latter is more efficient and allows for transparent videos, but two sources
  are needed since Safari has H.265, but Chrome/Firefox have VP9.
-->
<video
  loop
  muted
  playsinline
  disablepictureinpicture
  class={`${inset ? "absolute inset-0" : "w-full h-full"} ${clazz}`}
  {poster}
  style:object-fit={objectFit}
  bind:this={element}
>
  <slot />
</video>
